import * as React from "react";
import { Link } from "react-router-dom";

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import donate_top from '../../src/assets/images/donate_top.png';
import donate_middle from '../../src/assets/images/donate_middle.png';

const Donate:React.FC = () => {
    return (
      <div>

      <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={donate_top}
            alt={"Donate"}
          />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
                <Card variant="outlined" sx={{ borderWidth: 0 }}>
                    <CardContent>

                        <Typography align="left" variant="h4">
                          Please donate.
                        </Typography>

                        <Typography align="left" variant="h6">
                        <li><a href="https://www.facebook.com/AnimalInstitute" target="_blank">Facebook (via 'Donate' button at top right)</a></li>
                        </Typography>
                        
                        <Typography align="left" variant="h6">
                        <li><a href="https://www.paypal.com/donate/?hosted_button_id=67N7S6VWSGJJJ" target="_blank">PayPal</a></li>
                        </Typography>
                        
                        <Typography align="left" variant="h6">
                        <li>Venmo: <i>@AnimalInstitute</i></li>
                        </Typography>
                        
                        <Typography align="left" variant="h6">
                        <li><a href="https://a.co/981GV8V" target="_blank">Amazon Wish List</a></li>
                        </Typography>
                        
                        <Typography align="left" variant="h6">
                        <li>To send a check via mail, please contact us for our mailing address at:<br />help@animalinstitute.org</li>
                        </Typography>

                        <Typography align="left" variant="h5">
                          <h5>Thank you very much!</h5>
                        </Typography>



                        <Typography align="left" variant="body1">
                          As a 100%-volunteer 501(c)3 charitable nonprofit animal protection organization, our programs are entirely dependent upon small donations from individuals and other organizations who appreciate the unique grassroots community services we provide.
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                              Wildlife intervention requires cages and habitats, veterinary procedures, medicines &amp; vaccines, veterinary medical supplies and food.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                              Education & Community Outreach requires public speaking equipment, teaching aids, printed materials, and event supplies.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                              Technology Solutions require digital tools such as cloud-based resources (e.g. Amazon Web Services), though we receive the associated expert technical labor for free from our dedicated volunteers and partners!
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                              Our Lost Pet Recovery Team™️ is in need of specialized equipment (e.g. traps and cellular cameras) and the supplies that are consumed on a per-case basis.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                          <p>
                            Every dollar you donate goes directly to supporting these lifesaving program needs
                          </p>
                        </Typography>

                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
            <Box
                    component="img"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'none',
                      justify: 'top'
                    }}
                    src={donate_middle}
                    alt={"Donate"}
                  />
            </Grid>
        </Grid>
    </Box>
  </div>
    );
  };

  export default Donate;