import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import Copperhead from '../../src/assets/images/slider-minimal-slide-copperhead-1770x737.png';
import Cows from '../../src/assets/images/slider-minimal-slide-cows-1770x737.png';
import Coyote from '../../src/assets/images/slider-minimal-slide-coyote-1770x737.png';
import Dogs from '../../src/assets/images/slider-minimal-slide-dogs-1770x737.png';
import Kitten from '../../src/assets/images/slider-minimal-slide-kitten-1770x737.png';
import Opossums from '../../src/assets/images/slider-minimal-slide-opossums-1770x737.png';
import Pig from '../../src/assets/images/slider-minimal-slide-pig-1770x737.png';
import Raccoon from '../../src/assets/images/slider-minimal-slide-raccoon-1770x737.png';
import Sheep from '../../src/assets/images/slider-minimal-slide-sheep-1770x737.png';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      Raccoon,
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      Pig,
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      Kitten,
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      Cows,
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      Sheep,
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      Copperhead,
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      Dogs,
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      Opossums,
  },
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      Coyote,
  },
];

function SwipeableTextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  return (
    
    <Box sx={{ flexGrow: 1 }}>

      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
      >
        {images.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <Box
                component="img"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover'
                }}
                src={step.imgPath}
                alt={step.label}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>

      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            Next
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}

export default SwipeableTextMobileStepper;
