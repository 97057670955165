import * as React from "react";
import { Routes, Route, Link, Outlet } from "react-router-dom";

import Home from "./components/Home";
import LostPetRecoveryTeam from "./components/LostPetRecoveryTeam";
import Wildlife from "./components/Wildlife";
import Snakes from "./components/Snakes";
import Education from "./components/Education";
import Technology from "./components/Technology";
// import Volunteer from "./components/Volunteer";
// import Donate from "./components/Donate";





// import { Tabs } from "@mui/material";
// import { AITabs } from "./components/Tabs";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Footer from "./components/Footer";

//import "./App.css";
// import CBCarousel from "./components/CBCarousel";


import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Donate from "./components/Donate";
import Volunteer from "./components/Volunteer";

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function App() {
  return (


<ThemeProvider theme={darkTheme}>
      <CssBaseline />


    <div className="App">

      <ResponsiveAppBar />

      <Routes>
        <Route index element={<Home />} />
        <Route path="/lost-pet-recovery-team" element={<LostPetRecoveryTeam />} />
        <Route path="/wildlife" element={<Wildlife />} />
        <Route path="/snakes" element={<Snakes />} />
        <Route path="/education" element={<Education />} />
        <Route path="/tech" element={<Technology />} />
        <Route path="/volunteer" element={<Volunteer />} />
        <Route path="/donate" element={<Donate />} />

        <Route path="user" element={<User />}>
          <Route index element={<Profile />} />
          <Route path="profile" element={<Profile />} />
          <Route path="account" element={<Account />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>

      <Footer />
    </div>


    </ThemeProvider>

  );
}




function User() {
  return (
    <>
      <h1>User</h1>

      <nav>
        <Link to="profile">Profile</Link>
        <Link to="account">Account</Link>
      </nav>

      <Outlet />
    </>
  );
};

function Profile() {
  return (
    <>
      <h3>Profile</h3>
    </>
  );
};

function Account() {
  return (
    <>
      <h3>Account</h3>
    </>
  );
};

function NoMatch() {
  return (
    <>
      <h2>NoMatch</h2>
    </>
  );
};

export default App;