import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Carousel from "./Carousel";

import BensonFamily from '../../src/assets/images/benson-family-570x368.jpg';

const Home:React.FC = () => {
    return (
      <div>

        <Carousel />

        <Box sx={{ flexGrow: 1 }}>
            <Card variant="outlined" sx={{ borderWidth: 0 }}>
              <CardContent>
                  <Typography align="left" variant="h4">What We Do</Typography>
                  <Typography align="left">
                    The Animal Institute is a 100%-volunteer 501(c)3 charitable nonprofit animal protection organization. We address challenges and need gaps that other animal welfare organizations and government agencies do not. Some of our programs are local to specific communities at the grassroots level, while others address unfilled needs for large geographic areas.
                  </Typography>
              </CardContent>
          </Card>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={4} md={4}>
                    <Card variant="outlined" sx={{ borderWidth: 0 }}>
                        <CardContent>
                            <Typography align="left" variant="h5">Wildlife Clinic</Typography>
                            <Typography align="left">
                            Our wildlife rehabilitation experts are licensed by the Department of Natural Resources to save lives and provide care to wild animals in need.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} sm={4} md={4}>
                    <Card variant="outlined" sx={{ borderWidth: 0 }}>
                        <CardContent>
                            <Typography align="left" variant="h5">Education &amp; Community Outreach</Typography>
                            <Typography align="left">
                            We are committed to providing first-class educational opportunities and resources, to ensure that people understand and treasure the animals in their lives.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} sm={4} md={4}>
                    <Card variant="outlined" sx={{ borderWidth: 0 }}>
                        <CardContent>
                            <Typography align="left" variant="h5">Technology Solutions</Typography>
                            <Typography align="left">
                            In partnership with the <a href="https://www.cc.gatech.edu" target="_blank">Georgia Tech College of Computing</a>, we create revolutionary tools for animal protection and digital destinations for learning about animals.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>

<Box sx={{ flexGrow: 1 }}>
    <Grid container spacing={2}>
        <Grid xs={12} sm={6} md={6}>
            <Card variant="outlined" sx={{ borderWidth: 0 }}>
                <CardContent>
                    <Typography align="left" variant="h4">Why We Created the Animal Institute</Typography>
                    <Typography align="left" variant="body1">
                        <p>
                            With more than a dozen years each of active animal rescue experience, Joanne and Chris Benson recognized that there were many animal protection needs which were not being met - or even addressed - by the existing nonprofit organizations and government agencies. They founded the Animal Institute to address unmet needs pertaining to companion animals, farm animals, and wildlife.
                        </p>
                    </Typography>
                    <Typography align="left" variant="body1">
                        <p>
                            While some programs are local and grassroots in nature, others take advantage of Chris Benson's expertise in technology, software development, and artificial intelligence - and are scaled to provide essential services to large geographic areas and populations.
                        </p>
                    </Typography>
                    <Typography align="left" variant="body1">
                        <p>
                            Every dollar donated - and volunteer hour served - goes directly to implementing animal protection solutions, since the Animal Institute is a 100%-volunteer 501(c)3 charitable nonprofit animal protection organization. By design, there is no bureaucracy to support, and no jobs to fund. We are entirely focused on empowering our wonderful volunteers who do amazing things for the animals they love.
                        </p>
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
        <Grid xs={12} sm={6} md={6}>
        <Box
                component="img"
                sx={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'none'
                }}
                src={BensonFamily}
                alt={"Benson family"}
              />
        </Grid>
    </Grid>
</Box>

  </div>

  )};

  export default Home;