import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import lostpets_top from '../../src/assets/images/lostpets_top.png';
import lostpets_middle from '../../src/assets/images/lostpets_middle.png';


const LostPetRecoveryTeam:React.FC = () => {
  return (
      
    <div>

      <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={lostpets_top}
            alt={"Lost Pet Recovery Team"}
          />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
                <Card variant="outlined" sx={{ borderWidth: 0 }}>
                    <CardContent>
                        <Typography align="left" variant="h4">
                            Lost Pet Recovery Team™️
                        </Typography>
                        <Typography align="left" variant="h5">
                            Request Help:
                        </Typography>
                        <Typography align="left" variant="h6">
                            <a href="https://www.facebook.com/LostPetRecoveryTeam" target="_blank">Use the Message button on our Facebook page!</a>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                            <strong>CURRENTLY NOT OPERATING DUE TO NON-AVAILABILITY OF TRAINED VOLUNTEERS</strong>
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Your baby was there just a moment ago. Now she's gone. In the blink of an eye, your nightmare has become your reality. Your baby is out there in the world - alone, scared, lost. She doesn't know that cars kill. What do you do? How will you find her?
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Pets get lost all the time - especially skittish ones - and yet few people know how best to recover them. Lost pet recovery is an art and a science that requires skill and expertise few people have learned and developed. Few pet owners have access to such expertise, or even know it exists.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                 Various members of the Animal Institute's Lost Pet Recovery Team™️ have contributed their expertise and insights to collaboratively build an extremely productive and successful pet recovery protocol.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                The team currently saves dozens of lost pets each year, returning each recovered pet to their grateful owners.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                We provide lifesaving intervention and expert guidance.
                            </p>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
            <Box
                    component="img"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'none'
                    }}
                    src={lostpets_middle}
                    alt={"Lost Pet Recovery Team"}
                  />
            </Grid>
        </Grid>
    </Box>
  </div>

  );
};

export default LostPetRecoveryTeam;