import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';

//import { withStyles } from '@material-ui/core/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const styles = () => ({
    card: {
        maxWidth: 400
    },
    content: {
        marginTop: 1
    }
});

const Footer:React.FC = () => {
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={12} sm={6} md={6}>
                    <Card variant="outlined" sx={{ borderWidth: 0 }}>
                        <CardContent>
                            <Typography align="left" variant="h5">Social</Typography>
                            <Typography align="left">
                                <div>
                                    <a href="https://www.facebook.com/AnimalInstitute" target="_blank">Facebook | Animal Institute (Donations accepted)</a><br />
                                    <a href="https://www.facebook.com/AnimalInstitutewildlife" target="_blank">Facebook | Animal Institute's Wildlife Clinic</a><br />
                                    <a href="https://www.facebook.com/LostPetRecoveryTeam" target="_blank">Facebook | Animal Institute's Lost Pet Recovery Team</a><br />
                                    <a href="https://www.youtube.com/@AnimalInstitute-8337ANIMAL" target="_blank">YouTube | Animal Institute</a><br />
                                    <a href="https://www.linkedin.com/company/animalinstitute" target="_blank">LinkedIn | Animal Institute</a>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} sm={6} md={6}>
                    <Card variant="outlined" sx={{ borderWidth: 0 }}>
                        <CardContent>
                            <Typography align="left" variant="h5">Contact</Typography>
                            <Typography align="left">
                                <div>
                                    (833) 7-ANIMAL<br />
                                    (833) 726-4625<br />
                                    Emergency: help@animalinstitute.org<br />
                                    Non-Emergency: info@animalinstitute.org
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
  };

export default Footer;

//const MainContent = withStyles(styles)(({ classes }) => (