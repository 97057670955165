import * as React from "react";
import { Link } from "react-router-dom";

import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import volunteer_top from '../../src/assets/images/volunteer_top.png';
import volunteer_middle from '../../src/assets/images/volunteer_middle.png';

const Volunteer:React.FC = () => {
    return (
      <div>

      <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={volunteer_top}
            alt={"Volunteer"}
          />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
                <Card variant="outlined" sx={{ borderWidth: 0 }}>
                    <CardContent>

                        <Typography align="left" variant="h4">
                          Please volunteer.  We can't save lives without you.
                        </Typography>

                        <Typography align="left" variant="h5">
                          <h5>
                            To begin volunteering with one of our programs, please complete the form you find
                          </h5>
                        </Typography>

                        <Typography align="left" variant="h4">
                        <a href="https://docs.google.com/forms/d/e/1FAIpQLSdsplZkb5vLGYaKjilSJRjyn_P7DddUXT1g_7keNYTF65JeCw/viewform" target="_blank">HERE!</a>
                        </Typography>
                        
                        <Typography align="left" variant="h5">
                          <h5>It's often a family affair, and a great learning experience for our kids - pure charitable public service that saves lives.  NEVERGIVEUP!</h5>
                        </Typography>
                        
                        <Typography align="left" variant="h5">
                          <h5>Thank you very much!</h5>
                        </Typography>

                        <Typography align="left" variant="body1">
                          As a 100%-volunteer 501(c)3 charitable nonprofit animal protection organization, our programs are entirely dependent upon volunteers who give their time and passion to support the unique grassroots community services we provide.
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                              Our Lost Pet Recovery Team™️ will train you to become a lost pet recovery specialist!
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                              Opportunities with our Wildlife team range from wildlife care, handling, and transport, under the supervision of our licensed RVS Wildlife Rehabilitator, to (for the truly adventurous) snake wrangling.  Training and mentorship is provided to all Wildlife volunteers.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                              Education & Community Outreach needs teachers, storytellers, writers, editors, public speakers, or anyone aspiring to these.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                              Administration, fundraising, grant writing, accounting, and even quartermaster opportunities are available for volunteers with those highly-prized skills and interests.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                              Information Services needs highly skilled technologists in a variety of disciples - TypeScript, Rust, Go, Kubernetes + Istio, AWS, PostgreSQL, Android, iOS, and various fields of Deep Learning.  Remote internships for resumeable industry experience, academic capstone projects, and professional recommendations are available to volunteer technologists.
                            </p>
                        </Typography>

                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
            <Box
                    component="img"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'none',
                      justify: 'top'
                    }}
                    src={volunteer_middle}
                    alt={"Volunteer"}
                  />
            </Grid>
        </Grid>
    </Box>
  </div>
    );
  };

  export default Volunteer;