import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import snakes_top from '../../src/assets/images/snakes_top.png';
import snakes_middle from '../../src/assets/images/snakes_middle.png';

const Snakes:React.FC = () => {
    return (
      <div>

      <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={snakes_top}
            alt={"Snakes"}
          />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
                <Card variant="outlined" sx={{ borderWidth: 0 }}>
                    <CardContent>
                        <Typography align="left" variant="h4">
                            Live. Learn. Love.
                        </Typography>
                        <Typography align="left" variant="h5">
                            help@animalinstitute.org
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Snakes are everywhere in Georgia.  Though you will almost never see them, you are usually only a few feet away from the nearest snake.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Wikipedia notes, "According to a 2012 study, Georgia has 15.67 snakes per square mile, surpassing Arizona's 15.2 for the largest number in the country."
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                And our snake density is matched by our diversity of snake species. The Georgia Department of Natural Resources notes that we have 46 distinct species, 6 of which are venomous.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                The Animal Institute's volunteer snake handlers offer accurate species IDs, guidance on conflicts and coexistence, and occasionally hands-on intervention, conflict resolution, and relocation services.
                            </p>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
            <Box
                    component="img"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'none',
                      justify: 'top'
                    }}
                    src={snakes_middle}
                    alt={"Snakes"}
                  />
            </Grid>
        </Grid>
    </Box>
  </div>
    );
  };

  export default Snakes;