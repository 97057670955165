import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import education_top from '../../src/assets/images/tech_top.png';
import education_middle from '../../src/assets/images/tech_middle.png';

const Education:React.FC = () => {
    return (
      <div>

      <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={education_top}
            alt={"Technology"}
          />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
                <Card variant="outlined" sx={{ borderWidth: 0 }}>
                    <CardContent>
                        <Typography align="left" variant="h4">
                            Revolutionary Tools for Animal Protection!
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                The Animal Institute has hidden superpowers in the skills and expertise of our volunteers.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                The technology expertise and experience of some of our volunteers is truly world-class.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                We are leveraging that to create revolutionary tools for animal protection, provide digital destinations for learning about animals and for operational lifesaving needs.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Our partnership with the <a href="https://www.cc.gatech.edu" target="_blank">Georgia Tech College of Computing</a> is central to our technology strategy.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                The Animal Institute provides student capstone projects, and mentors students in the development and realization of those capstone projects.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Each of those capstone projects will be made available as free public services from the Animal Institute.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Stay tuned for a series of upcoming announcement about these amazing new capabilities!
                            </p>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
            <Box
                    component="img"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'none',
                      justify: 'top'
                    }}
                    src={education_middle}
                    alt={"Technology"}
                  />
            </Grid>
        </Grid>
    </Box>
  </div>
    );
  };

  export default Education;