import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import education_top from '../../src/assets/images/education_top.png';
import education_middle from '../../src/assets/images/education_middle.png';

const Education:React.FC = () => {
    return (
      <div>

      <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={education_top}
            alt={"Education"}
          />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
                <Card variant="outlined" sx={{ borderWidth: 0 }}>
                    <CardContent>
                        <Typography align="left" variant="h4">
                            Live. Learn. Love.
                        </Typography>
                        <Typography align="left" variant="h5">
                            help@animalinstitute.org
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                We can do better. We must do better.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Animals are not objects to be used, discarded, or feared. They are not resources to be consumed, managed, or culled.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Animals feel. They hurt. They fear. They are desperate to survive, raise their young, and be left alone.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                Our responsibility for them - and duty to them - is not to dominate and manage. It is to coexist, and share the world around us.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                The more we learn, the more we understand, the more we empathize, and the less we fear. Live. Learn. Love.
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                We will soon be starting a recurring series of wildlife educational events at Kemp Park on Burnt Hickory Road in western Cobb County, Georgia.  Details will be announced soon!
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                We are open to offering various types of animal educational events at parks, schools, libraries, businesses, etc.,
                            </p>
                        </Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
            <Box
                    component="img"
                    sx={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'none',
                      justify: 'top'
                    }}
                    src={education_middle}
                    alt={"Education"}
                  />
            </Grid>
        </Grid>
    </Box>
  </div>
    );
  };

  export default Education;