import * as React from "react";
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import wildlife_top from '../../src/assets/images/wildlife_top.png';
import wildlife_middle from '../../src/assets/images/wildlife_middle.png';

const Wildlife:React.FC = () => {
    return (
      <div>

      <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover'
            }}
            src={wildlife_top}
            alt={"Wildlife"}
          />

      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={6}>
                <Card variant="outlined" sx={{ borderWidth: 0 }}>
                    <CardContent>
                        <Typography align="left" variant="h4">
                            Animal Institute's Wildlife Clinic
                        </Typography>

                        <hr />
                        <Typography align="left" variant="h6">
                            Injured, Ill, and Orphaned Wildlife
                        </Typography>
                         <hr />

                         <Typography align="left" variant="body1">
                            <p>
                                <strong>SIGNIFICANTLY REDUCED OPERATIONS / INTAKE DUE TO REHABBER CONSTRAINTS</strong>
                            </p>
                            <p>
                                When you find a wild creature in need of assistance, first make sure it really needs assistance. Some usually do (baby squirrels), and some usually don’t (fawns). Don’t kidnap a perfectly healthy fawn left alone while its mother forages nearby, unless it is clearly sick or injured, and remember that you will likely never see the mom - so don’t panic. Newborn fawns that are in or by roadways should be picked up and placed 50-100 feet away to avoid a tragedy.
                            </p>
                            <p>
                                For a wild creature that is injured, ill, or orphaned, follow these three simple rules:
                            </p>
                            <ol>
                                <li>Keep it safe, secure, warm, quiet, dark, and away from people, pets, and predators. Do not handle more than absolutely necessary, because you are a giant predator to a wild animal, and unnecessary stress can kill. Having said that, a little of your scent on a baby will not make mom reject it, but minimize scent transfer by wearing exam gloves or similar.  Place a heating pad on low under (only) half of the container it is in, because babies and compromised adults may need help maintaining a healthy body temperature.</li>
                                <p />
                                <li>Do NOT feed or water. Nothing at all! Yes, it’s counterintuitive, but critically important! The websites you are getting your information from are often WRONG - but not this one.  ;-)</li>
                                <p />
                                <li>Do NOT post for help on social media. If you have already posted, please delete it. The comments you receive from supposedly experienced amateurs and wannabe rehabbers are often wrong - and frequently result in fatal outcomes with increased suffering.</li>
                                <p />
                                <li>Contact a licensed wildlife rehabilitator immediately - specifically one with expertise in the species. That person will provide all guidance and instruction thereafter.
                                    <p />
                                    Wildlife Rehabilitators (aka rehabbers) are licensed and regulated by state and federal laws and regulations. A person who is a legitimate wildlife rehabilitator can produce a current license to establish credentials.</li>
                            </ol>
                        </Typography>

                        <hr />
                        <Typography align="left" variant="h6">
                            Raccoons • Foxes • Coyotes • Bobcats • Skunks • Bats
                        </Typography>
                         <hr />

                         <Typography align="left" variant="body1">
                            <p>
                                Our Wildlife Clinic is managed by Chris Benson, an experienced Wildlife Rehabilitator, licensed by the Georgia Department of Natural Resources, and certified by the same for 'rabies vector species' (RVS).
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                The <strong>Animal Institute's Wildlife Clinic</strong> specializes in treating 'rabies vector species' (RVS):
                            </p>
                            <ul>
                                <li><strong>raccoons</strong></li>
                                <li><strong>foxes</strong></li>
                                <li><strong>coyotes</strong></li>
                                <li><strong>bobcats</strong></li>
                                <li><strong>skunks</strong></li>
                                <li><strong>bats</strong></li>
                            </ul>
                            <p>
                                We <strong>DO NOT ACCEPT</strong> or treat any species not listed above.
                            </p>
                            <p>
                                Even for those we do treat, <strong>WE MAY BE UNABLE TO ACCEPT DUE TO CAPACITY LIMITS</strong>.
                            </p>
                            <p>
                                To find a rehabber for these and other species not listed above, please see:<br />
                                <strong><a href="#other">Linked Resources For All Other Species</a></strong>
                            </p>
                        </Typography>

                         <hr />
                        <Typography align="left" variant="h6">
                            How to contact the Animal Institute's Wildlife Clinic
                        </Typography>
                         <hr />

                        <Typography align="left" variant="body1">
                            <p>
                                ALWAYS send an email to <strong><i>help@animalinstitute.org</i></strong> with <strong>ALL</strong> of the following information, EVEN IF you are calling our phone number:
                            </p>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <ul>
                                <li>First & last name</li>
                                <li>Cell phone number</li>
                                <li>Complete home street address</li>
                                <li>Complete street address / GPS coordinates of wildlife's point of origin</li>
                                <li>Email address that you actively monitor throughout the day</li>
                                <li>Details of your wildlife emergency, with a complete timeline that includes every decision and action taken</li>
                                <li>Detailed closeup photos that include full perspective and injuries</li>
                                <li>Availability to receive a phone call from an anonymous phone number (no caller id)</li>
                            </ul>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                We give priority attention to the species that we address directly (because we are often overwhelmed with requests for help):
                            </p>
                            <ul>
                                <li><strong>raccoons</strong></li>
                                <li><strong>foxes</strong></li>
                                <li><strong>coyotes</strong></li>
                                <li><strong>bobcats</strong></li>
                                <li><strong>skunks</strong></li>
                                <li><strong>bats</strong></li>
                            </ul>
                        </Typography>
                        <Typography align="left" variant="body1">
                            <p>
                                As time allows for other species, and if you provide ALL of the information requested above, we may assist you with initial guidance and getting you connected with a wildlife rehabilitator in your area who specializes in the little one you are caring for.
                            </p>
                        </Typography>
                        <Typography align="left" variant="h5">
                             <a href="mailto:help@animalinstitute.org">help@animalinstitute.org</a>
                        </Typography>
                        <Typography align="left" variant="h5">
                            <a href="tel:+18337264625">(833) 7-ANIMAL</a>
                        </Typography>

                        <a id="other" />
                        <hr />
                        <Typography align="left" variant="h6">
                            Linked Resources For All Other Species
                        </Typography>
                         <hr />

                        <Typography align="left" variant="body1">
                            <p>
                                For other species not listed above, here are resources with which you can find a rehabber who specializes in the species you are seeking help for:
                            </p>
                        </Typography>

                        <Typography align="left" variant="body1">
                            <p>
                                <strong>Animal Help Now!</strong><br />
                                A website and mobile app that lists licensed wildlife rehabilitators. It covers the entire United States, not just Georgia. Highly recommended to keep on your phone.
                            </p>
                            <ul>
                                <li><a href="https://ahnow.org" target="_top">Animal Help Now!</a><br />(all species)</li>
                                <li><a href="https://apps.apple.com/us/app/animal-help-now/id511153457" target="_top">Animal Help Now!<br />iPhone app</a><br />(all species)</li>
                                <li><a href="https://play.google.com/store/apps/details?id=org.ahnow" target="_top">Animal Help Now!<br />Android / Google Play app</a><br />(all species)</li>
                            </ul>
                        </Typography>        


                        <Typography align="left" variant="body1">
                            <p>
                                <strong>Georgia Department of Natural Resources Wildlife Rehabilitators List (PDF)</strong><br />
                                Includes licensed wildlife rehabilitators who have opted-in to the ‘DNR public list’, but does not comprehensively list all licensed wildlife rehabilitators.
                            </p>
                            <ul>
                                <li><a href="https://gadnrle.org/sites/default/files/le/pdf/Special-Permits/WILDLIFE%20REHABILITATION%20%202023-2%20%20Q2%20Updates%20May.pdf" target="_top">Georgia Department of Natural Resources<br />Wildlife Rehabilitators List</a><br />(all species)</li>
                                <li><a href="https://gadnrwrd.maps.arcgis.com/apps/webappviewer/index.html?id=cfeac720835c407594b2407c4aefe32b" target="_top">Georgia Department of Natural Resources<br />Wildlife Rehabilitators Map</a><br />(all species)</li>
                            </ul>
                        </Typography>  
                                
                        <Typography align="left" variant="body1">
                            <p>
                                <strong>Wildlife Rehabilitators</strong>
                            </p>
                            <ul>
                                <li><a href="https://www.chattnaturecenter.org/visit/experience/wildlife" target="_top">Chattahoochee Nature Center Wildlife Clinic</a><br />(raptors, reptiles, and amphibians only)</li>
                                <li><a href="https://www.wildnestbirdrehab.org" target="_top">Wild Nest Bird Rehab</a><br />(songbirds / passerines only)</li>
                                <li><a href="https://www.waddleoninnrescue.org" target="_top">Waddle On Inn Rescue</a><br />(waterfowl only)</li>
                            </ul>
                        </Typography>  

                    </CardContent>
                </Card>
            </Grid>
            <Grid xs={12} sm={6} md={6}>
                    <Box
                            component="img"
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'none'
                            }}
                            src={wildlife_middle}
                            alt={"Wildlife"}
                        />
                
            </Grid>
        </Grid>
    </Box>
  </div>
    );
  };

  export default Wildlife;